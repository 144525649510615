import React, { useState } from "react"
import { Card, CardContent } from "@/components/ui/card"
import { IconButton } from "components/ui/icon-button"
import {
    Trash2Icon,
    PencilIcon,
    Loader,
    ChevronDownIcon,
    ChevronUpIcon,
} from "lucide-react"
import CheckInImages from "./CheckInImages"
import { useTranslation } from "react-i18next"
import { useThemeStore } from "stores/useThemeStore" // Assuming you have a theme store

interface CheckInItemProps {
    checkIn: CheckIn
    onEdit: () => void
    onDelete: () => void
    onImageClick: (url: string) => void
    isLoading: boolean
}

const CheckInItem: React.FC<CheckInItemProps> = ({
    checkIn,
    onEdit,
    onDelete,
    onImageClick,
    isLoading,
}) => {
    const { t } = useTranslation()
    const { theme } = useThemeStore() // Assume this gets the current theme
    const [isCollapsed, setIsCollapsed] = useState(false)

    // Define colors for light and dark themes
    const bgColor = theme === "dark" ? "bg-gray-800" : "bg-gray-200"
    const cardTextColor = theme === "dark" ? "text-white" : "text-black"
    const iconColor = theme === "dark" ? "text-white" : "text-black"
    const buttonBgColor = theme === "dark" ? "bg-gray-700" : "bg-gray-300"
    const statBgColor = theme === "dark" ? "bg-gray-800" : "bg-gray-100" // Lighter background for stats in light mode

    return (
        <Card className={`relative shadow-md rounded-lg p-6 ${bgColor}`}>
            <div className="absolute top-2 right-2 flex space-x-2">
                {isLoading ? (
                    <Loader className="animate-spin h-6 w-6 text-gray-500" />
                ) : (
                    <>
                        <IconButton
                            onClick={onEdit}
                            className={`${buttonBgColor} p-2 rounded-full`}
                        >
                            <PencilIcon className={iconColor} />
                        </IconButton>
                        <IconButton
                            onClick={onDelete}
                            className={`${buttonBgColor} p-2 rounded-full`}
                        >
                            <Trash2Icon className={iconColor} />
                        </IconButton>
                    </>
                )}
            </div>

            <CardContent className="p-0">
                <h2
                    className={`text-md sm:text-lg font-bold mb-4 ${cardTextColor}`}
                >
                    {new Date(checkIn.checkInDate).toLocaleDateString()}
                </h2>

                <div className="flex justify-between items-center mb-4">
                    <h3 className={`text-lg font-semibold ${cardTextColor}`}>
                        {t("measurements")}
                    </h3>
                    <IconButton
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        className={`p-2 rounded-full ${buttonBgColor}`}
                    >
                        {isCollapsed ? (
                            <ChevronUpIcon className={iconColor} />
                        ) : (
                            <ChevronDownIcon className={iconColor} />
                        )}
                    </IconButton>
                </div>

                {!isCollapsed && (
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-2">
                        <div className={`p-2 ${statBgColor} rounded-lg`}>
                            <h3
                                className={`text-sm font-semibold ${cardTextColor}`}
                            >
                                {t("weight")}
                            </h3>
                            <p className={`text-lg font-bold ${cardTextColor}`}>
                                {checkIn.weight
                                    ? `${checkIn.weight} kg`
                                    : t("missing_value")}
                            </p>
                        </div>
                        <div className={`p-2 ${statBgColor} rounded-lg`}>
                            <h3
                                className={`text-sm font-semibold ${cardTextColor}`}
                            >
                                {t("chest")}
                            </h3>
                            <p className={`text-lg font-bold ${cardTextColor}`}>
                                {checkIn.chestMeasurement
                                    ? `${checkIn.chestMeasurement} cm`
                                    : t("missing_value")}
                            </p>
                        </div>
                        <div className={`p-2 ${statBgColor} rounded-lg`}>
                            <h3
                                className={`text-sm font-semibold ${cardTextColor}`}
                            >
                                {t("waist")}
                            </h3>
                            <p className={`text-lg font-bold ${cardTextColor}`}>
                                {" "}
                                {checkIn.waistMeasurement
                                    ? `${checkIn.waistMeasurement} cm`
                                    : t("missing_value")}
                            </p>
                        </div>
                        <div className={`p-2 ${statBgColor} rounded-lg`}>
                            <h3
                                className={`text-sm font-semibold ${cardTextColor}`}
                            >
                                {t("hip")}
                            </h3>
                            <p className={`text-lg font-bold ${cardTextColor}`}>
                                {checkIn.hipMeasurement
                                    ? `${checkIn.hipMeasurement} cm`
                                    : t("missing_value")}
                            </p>
                        </div>
                        <div className={`p-2 ${statBgColor} rounded-lg`}>
                            <h3
                                className={`text-sm font-semibold ${cardTextColor}`}
                            >
                                {t("arm")}
                            </h3>
                            <p className={`text-lg font-bold ${cardTextColor}`}>
                                {checkIn.armMeasurement
                                    ? `${checkIn.armMeasurement} cm`
                                    : t("missing_value")}
                            </p>
                        </div>
                        <div className={`p-2 ${statBgColor} rounded-lg`}>
                            <h3
                                className={`text-sm font-semibold ${cardTextColor}`}
                            >
                                {t("leg")}
                            </h3>
                            <p className={`text-lg font-bold ${cardTextColor}`}>
                                {checkIn.legMeasurement
                                    ? `${checkIn.legMeasurement} cm`
                                    : t("missing_value")}
                            </p>
                        </div>
                    </div>
                )}

                <div className="pt-4">
                    <h3 className={`font-semibold mb-2 ${cardTextColor}`}>
                        {t("note")}:
                    </h3>
                    <p className={`text-sm ${cardTextColor}`}>
                        {checkIn.note || t("no_note")}
                    </p>
                </div>

                <CheckInImages
                    frontPictureUrl={checkIn.frontPictureUrl}
                    sidePictureUrl={checkIn.sidePictureUrl}
                    backPictureUrl={checkIn.backPictureUrl}
                    onImageClick={onImageClick}
                />
            </CardContent>
        </Card>
    )
}

export default CheckInItem
