import React, { useEffect, useState } from "react"
import { useCheckInStore } from "stores/useCheckInStore"
import CheckInForm from "./CheckInForm"
import { useTranslation } from "react-i18next"
import { Button } from "components/ui/button"
import CheckInItem from "./CheckInItem"
import ImageModal from "components/shared/ImageModal"

const defaultFormValues = {
    weight: 0,
    chestMeasurement: 0,
    waistMeasurement: 0,
    hipMeasurement: 0,
    armMeasurement: 0,
    legMeasurement: 0,
    frontPictureUrl: null,
    sidePictureUrl: null,
    backPictureUrl: null,
    note: "",
}

const CheckInList: React.FC = () => {
    const { t } = useTranslation()
    const { checkIns, fetchCheckIns, deleteCheckIn, error, clearError } =
        useCheckInStore((state) => ({
            checkIns: state.checkIns,
            fetchCheckIns: state.fetchCheckIns,
            deleteCheckIn: state.deleteCheckIn,
            error: state.error,
            clearError: state.clearError,
        }))

    useEffect(() => {
        fetchCheckIns()
    }, [fetchCheckIns])

    const [open, setOpen] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [formInitialValues, setFormInitialValues] = useState<any>(null)
    const [loadingCheckInId, setLoadingCheckInId] = useState<string | null>(
        null
    )

    const handleOpen = () => {
        const latestCheckIn = checkIns.length > 0 ? checkIns[0] : null
        setFormInitialValues(latestCheckIn || defaultFormValues)
        setIsEditing(false)
        setOpen(true)
    }

    const handleEdit = (checkIn: CheckIn) => {
        setFormInitialValues(checkIn)
        setIsEditing(true)
        setOpen(true)
    }

    const handleClose = () => {
        clearError()
        setOpen(false)
    }

    const handleImageClick = (url: string) => {
        setSelectedImage(url)
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setSelectedImage(null)
    }

    const handleDeleteCheckIn = async (id: string) => {
        setLoadingCheckInId(id)
        try {
            await deleteCheckIn(id)
        } catch {
            // Error handling is done in the store
        } finally {
            setLoadingCheckInId(null)
        }
    }

    return (
        <div className="p-4 sm:p-6 w-full max-w-4xl mx-auto">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl sm:text-2xl font-semibold">
                    {t("your_check_ins")}
                </h1>
                <Button
                    variant="default"
                    className=" text-black dark:text-white bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700"
                    onClick={handleOpen}
                >
                    {t("new_check_in")}
                </Button>
            </div>

            {error && <p className="text-red-500 mb-4">{t(error)}</p>}

            {checkIns.length > 0 ? (
                <div className="grid grid-cols-1 gap-4 sm:gap-6">
                    {checkIns.map((checkIn) => (
                        <CheckInItem
                            key={checkIn.id}
                            checkIn={checkIn}
                            onEdit={() => handleEdit(checkIn)}
                            onDelete={() => handleDeleteCheckIn(checkIn.id)}
                            onImageClick={handleImageClick}
                            isLoading={loadingCheckInId === checkIn.id}
                        />
                    ))}
                </div>
            ) : (
                <p className="text-gray-500">{t("no_check_ins_yet")}</p>
            )}

            <ImageModal
                selectedImage={selectedImage}
                modalOpen={modalOpen}
                handleModalClose={handleModalClose}
            />

            <CheckInForm
                open={open}
                handleClose={handleClose}
                initialValues={formInitialValues}
                isEditing={isEditing}
            />
        </div>
    )
}

export default CheckInList
