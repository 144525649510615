import React from "react"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"

interface FormFieldsProps {
    weight: number | string
    chestMeasurement: number | string
    waistMeasurement: number | string
    hipMeasurement: number | string
    armMeasurement: number | string
    legMeasurement: number | string
    handleChange: (field: string, value: number | string) => void
}

const FormFields: React.FC<FormFieldsProps> = ({
    weight,
    chestMeasurement,
    waistMeasurement,
    hipMeasurement,
    armMeasurement,
    legMeasurement,
    handleChange,
}) => {
    return (
        <>
            <div>
                <Label htmlFor="weight">Weight</Label>
                <Input
                    id="weight"
                    type="number"
                    step={0.1}
                    value={weight}
                    onChange={(e) =>
                        handleChange("weight", parseFloat(e.target.value))
                    }
                    className="bg-input text-foreground bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                />
            </div>

            <div>
                <Label htmlFor="chestMeasurement">Chest Measurement</Label>
                <Input
                    id="chestMeasurement"
                    type="number"
                    step={0.1}
                    value={chestMeasurement}
                    onChange={(e) =>
                        handleChange(
                            "chestMeasurement",
                            parseFloat(e.target.value)
                        )
                    }
                    className="bg-input text-foreground bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                />
            </div>

            <div>
                <Label htmlFor="waistMeasurement">Waist Measurement</Label>
                <Input
                    id="waistMeasurement"
                    type="number"
                    step={0.1}
                    value={waistMeasurement}
                    onChange={(e) =>
                        handleChange(
                            "waistMeasurement",
                            parseFloat(e.target.value)
                        )
                    }
                    className="bg-input text-foreground bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                />
            </div>

            <div>
                <Label htmlFor="hipMeasurement">Hip Measurement</Label>
                <Input
                    id="hipMeasurement"
                    type="number"
                    step={0.1}
                    value={hipMeasurement}
                    onChange={(e) =>
                        handleChange(
                            "hipMeasurement",
                            parseFloat(e.target.value)
                        )
                    }
                    className="bg-input text-foreground bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                />
            </div>

            <div>
                <Label htmlFor="armMeasurement">Arm Measurement</Label>
                <Input
                    id="armMeasurement"
                    type="number"
                    step={0.1}
                    value={armMeasurement}
                    onChange={(e) =>
                        handleChange(
                            "armMeasurement",
                            parseFloat(e.target.value)
                        )
                    }
                    className="bg-input text-foreground bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                />
            </div>

            <div>
                <Label htmlFor="legMeasurement">Leg Measurement</Label>
                <Input
                    id="legMeasurement"
                    type="number"
                    step={0.1}
                    value={legMeasurement}
                    onChange={(e) =>
                        handleChange(
                            "legMeasurement",
                            parseFloat(e.target.value)
                        )
                    }
                    className="bg-input text-foreground bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                />
            </div>
        </>
    )
}

export default FormFields
