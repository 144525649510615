import React from "react"
import { useTranslation } from "react-i18next"

interface CheckInComparisonProps {
    checkIn: CheckIn
    handleImageClick: (url: string) => void
    title: string
}

const CheckInComparison: React.FC<CheckInComparisonProps> = ({
    checkIn,
    handleImageClick,
    title,
}) => {
    const { t } = useTranslation()

    if (!checkIn) {
        return null
    }

    return (
        <div className="space-y-4">
            <h3 className="text-center">{title}</h3>

            <div className="flex flex-wrap justify-center gap-4">
                {checkIn?.frontPictureUrl && (
                    <div
                        className="relative group cursor-pointer"
                        onClick={() =>
                            handleImageClick(checkIn.frontPictureUrl)
                        }
                    >
                        <img
                            className="h-36 w-36 sm:h-40 sm:w-40 object-cover rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300"
                            src={checkIn.frontPictureUrl}
                            alt={t("front_picture")}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <p className="text-white font-bold">{t("front")}</p>
                        </div>
                    </div>
                )}
                {checkIn.sidePictureUrl && (
                    <div
                        className="relative group cursor-pointer"
                        onClick={() => handleImageClick(checkIn.sidePictureUrl)}
                    >
                        <img
                            className="h-36 w-36 sm:h-40 sm:w-40 object-cover rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300"
                            src={checkIn.sidePictureUrl}
                            alt={t("side_picture")}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <p className="text-white font-bold">{t("side")}</p>
                        </div>
                    </div>
                )}
                {checkIn.backPictureUrl && (
                    <div
                        className="relative group cursor-pointer"
                        onClick={() => handleImageClick(checkIn.backPictureUrl)}
                    >
                        <img
                            className="h-36 w-36 sm:h-40 sm:w-40 object-cover rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300"
                            src={checkIn.backPictureUrl}
                            alt={t("back_picture")}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <p className="text-white font-bold">{t("back")}</p>
                        </div>
                    </div>
                )}
            </div>
            <p className="text-center mt-2 text-sm sm:text-base">
                {new Date(checkIn.checkInDate).toLocaleDateString()}
            </p>
        </div>
    )
}

export default CheckInComparison
