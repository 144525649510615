import React, { useEffect, useState } from "react"
import { useAuthStore } from "stores/useAuthStore"
import { useTranslation } from "react-i18next"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Loader2 } from "lucide-react"

const Profile: React.FC = () => {
    const { t } = useTranslation()
    const { user, fetchUser, updateUser } = useAuthStore()
    const [formData, setFormData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)
    const [updating, setUpdating] = useState<boolean>(false)

    useEffect(() => {
        const loadUserData = async () => {
            try {
                await fetchUser()
                setLoading(false)
            } catch (err) {
                setError(t("fetch_error"))
                setLoading(false)
            }
        }

        loadUserData()
    }, [fetchUser, t])

    useEffect(() => {
        if (user) {
            setFormData(user)
        }
    }, [user])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setUpdating(true)
        setError(null)
        try {
            await updateUser(formData)
        } catch (err) {
            setError(t("update_error"))
        } finally {
            setUpdating(false)
        }
    }

    if (loading) {
        return (
            <div className="p-4 sm:p-6 w-full max-w-lg mx-auto bg-white dark:bg-black">
                <Loader2 className="h-12 w-12 animate-spin text-black dark:text-white" />
            </div>
        )
    }

    return (
        <div className="p-4 sm:p-6 w-full max-w-lg mx-auto bg-white dark:bg-black">
            <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-black dark:text-white">
                {t("profile")}
            </h1>

            <div className="w-full max-w-md bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 p-6">
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {user && (
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-1"
                            >
                                {t("email")}
                            </label>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                value={formData?.email || ""}
                                onChange={handleChange}
                                className="w-full bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                                disabled
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="age"
                                className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-1"
                            >
                                {t("age")}
                            </label>
                            <Input
                                id="age"
                                name="age"
                                type="number"
                                value={formData?.age || ""}
                                onChange={handleChange}
                                className="w-full bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="height"
                                className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-1"
                            >
                                {t("height")}
                            </label>
                            <Input
                                id="height"
                                step={0.1}
                                name="height"
                                type="number"
                                value={formData?.height || ""}
                                onChange={handleChange}
                                className="w-full bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="weight"
                                className="block text-sm font-medium text-gray-900 dark:text-gray-100 mb-1"
                            >
                                {t("weight")} (kg)
                            </label>
                            <Input
                                id="weight"
                                step={0.1}
                                name="weight"
                                type="number"
                                value={formData?.weight || ""}
                                onChange={handleChange}
                                className="w-full bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                            />
                        </div>

                        <Button
                            type="submit"
                            className="w-full mt-4 bg-black dark:black hover:bg-blue-700 dark:hover:bg-blue-400 text-white rounded-md py-2 transition-all"
                            disabled={updating}
                        >
                            {updating ? (
                                <Loader2 className="h-5 w-5 animate-spin mr-2 inline-block" />
                            ) : (
                                t("update")
                            )}
                        </Button>
                    </form>
                )}
            </div>
        </div>
    )
}

export default Profile
