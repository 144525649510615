import axios from "axios"
import apiClient from "utils/api"

const handleError = (error: any) => {
    // You can customize this error handler to better fit your app's needs
    if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data || "An unexpected error occurred")
    } else {
        throw new Error("An unexpected error occurred")
    }
}

export const getProgressViews = async () => {
    try {
        const response = await apiClient.get("/progressview")
        return response.data
    } catch (error) {
        handleError(error)
    }
}

export const createProgressView = async (checkInIds: string[]) => {
    try {
        const response = await apiClient.post("/progressview", {
            checkInIds,
        })
        return response.data
    } catch (error) {
        handleError(error)
    }
}

export const updateProgressView = async (id: string, checkInIds: string[]) => {
    try {
        await apiClient.put(`/progressview/${id}`, { checkInIds })
    } catch (error) {
        handleError(error)
    }
}

export const deleteProgressView = async (id: string) => {
    try {
        await apiClient.delete(`/progressview/${id}`)
    } catch (error) {
        handleError(error)
    }
}

export const getPublicProgressView = async (id: string) => {
    try {
        const response = await apiClient.get(`/progressview/public/${id}`)
        return response.data
    } catch (error) {
        throw new Error(
            error.response?.data?.message || "Error fetching progress view."
        )
    }
}
