import { ButtonHTMLAttributes } from "react"
import { cn } from "@/lib/utils" // Utility to combine class names

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
}

export const IconButton: React.FC<IconButtonProps> = ({
    className,
    ...props
}) => {
    return (
        <button
            type="button"
            className={cn(
                "p-2 rounded-full hover:bg-black/10 dark:hover:bg-white/10 transition-colors",
                className
            )}
            {...props}
        />
    )
}
