import React from "react"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts"

interface TrendChartProps {
    data: { date: string; value: number }[] // Data should include date and value
    title: string
    dataKey: string
    color: string
}

const TrendChart: React.FC<TrendChartProps> = ({
    data,
    title,
    dataKey,
    color,
}) => {
    // Sorting data by date in ascending order
    const sortedData = data.slice().sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
    })

    return (
        <div className="mt-4">
            <h2 className="text-md sm:text-lg font-medium mb-2">{title}</h2>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={sortedData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis
                        domain={["auto", "auto"]}
                        tickLine={false}
                        axisLine={false}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey={dataKey} stroke={color} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default TrendChart
