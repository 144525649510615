import React from "react"
import { cn } from "@/lib/utils" // Utility to handle conditional classNames if needed

interface NavbarProps {
    children: React.ReactNode
    className?: string // Optional className prop
}

const Navbar: React.FC<NavbarProps> = ({ children, className }) => (
    <nav
        className={cn(
            "bg-white dark:bg-black text-black dark:text-white p-4 shadow-lg",
            className
        )}
    >
        {children}
    </nav>
)

export { Navbar }
