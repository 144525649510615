import React from "react"
import { Card, CardContent } from "@/components/ui/card"
import CheckInImages from "components/check-in/CheckInImages"
import { useTranslation } from "react-i18next"

interface SimpleCheckInItemProps {
    checkIn: PublicCheckInDto
    onImageClick: (url: string) => void
}

const PublicSimpleCheckInItem: React.FC<SimpleCheckInItemProps> = ({
    checkIn,
    onImageClick,
}) => {
    const { t } = useTranslation()

    return (
        <Card className={`relative shadow-md rounded-lg p-6 bg-gray-900`}>
            <CardContent className="p-0">
                <div className="flex justify-between items-center mb-4">
                    <h3 className={`text-xl font-semibold text-white`}>
                        {new Date(checkIn.checkInDate).toLocaleDateString()}
                    </h3>
                    {checkIn.weight && (
                        <p className="text-white">
                            {t("weight")}: {checkIn.weight} kg
                        </p>
                    )}
                </div>
                <div>
                    <CheckInImages
                        frontPictureUrl={checkIn.frontPictureUrl}
                        sidePictureUrl={checkIn.sidePictureUrl}
                        backPictureUrl={checkIn.backPictureUrl}
                        onImageClick={onImageClick}
                    />
                </div>
            </CardContent>
        </Card>
    )
}

export default PublicSimpleCheckInItem
