import React, { useEffect } from "react"
import { useProgressViewStore } from "stores/useProgressViewStore"
import { Button } from "components/ui/button"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const ProgressViewList: React.FC = () => {
    const { t } = useTranslation()
    const { progressViews, fetchProgressViews, deleteProgressView } =
        useProgressViewStore()

    useEffect(() => {
        fetchProgressViews()
    }, [fetchProgressViews])

    const handleDeleteProgressView = (id: string) => {
        deleteProgressView(id)
    }

    return (
        <div className="p-4 sm:p-6 w-full max-w-4xl mx-auto">
            <h1 className="text-2xl sm:text-3xl font-bold mb-6">
                {t("your_progress_views")}
            </h1>

            {/* Create Progress View Button */}
            <div className="mb-6">
                <Link to="/progress-views/create">
                    <Button
                        variant="default"
                        className="text-black dark:text-white bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700"
                    >
                        {t("create_progress_view")}
                    </Button>
                </Link>
            </div>

            {/* List of Generated Progress Views */}
            <div className="mt-8">
                {progressViews.length > 0 ? (
                    <div className="grid grid-cols-1 gap-4">
                        {progressViews.map((progressView) => (
                            <div
                                key={progressView.id}
                                className="flex justify-between items-center p-4 bg-gray-200 dark:bg-gray-800 rounded-md"
                            >
                                <span>
                                    {new Date(
                                        progressView.createdDate
                                    ).toLocaleDateString()}{" "}
                                    {new Date(
                                        progressView.createdDate
                                    ).toLocaleTimeString()}{" "}
                                    <Link
                                        className="text-blue-600 hover:underline"
                                        to={`/public/progress-view/${progressView.id}`}
                                    >
                                        {progressView.id}
                                    </Link>
                                </span>
                                <Button
                                    variant="destructive"
                                    className="text-white bg-red-600 hover:bg-red-700"
                                    onClick={() =>
                                        handleDeleteProgressView(
                                            progressView.id
                                        )
                                    }
                                >
                                    {t("delete")}
                                </Button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-500">{t("no_progress_views")}</p>
                )}
            </div>
        </div>
    )
}

export default ProgressViewList
