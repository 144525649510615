import React, { useEffect, useState } from "react"
import { useProgressViewStore } from "stores/useProgressViewStore"
import { useCheckInStore } from "stores/useCheckInStore"
import { Button } from "components/ui/button"
import SimpleCheckInItem from "components/progress-view/SimpleCheckInItem"
import ImageModal from "components/shared/ImageModal"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const ProgressViewCreate: React.FC = () => {
    const { t } = useTranslation() // Translation hook
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    const { createProgressView } = useProgressViewStore()
    const { checkIns, fetchCheckIns } = useCheckInStore()
    const [selectedCheckInIds, setSelectedCheckInIds] = useState<string[]>([])
    const [expandedCheckInIds, setExpandedCheckInIds] = useState<string[]>([])

    useEffect(() => {
        fetchCheckIns()
    }, [fetchCheckIns])

    const handleCreateProgressView = async () => {
        try {
            await createProgressView(selectedCheckInIds)
            navigate("/progress-views") // Redirect to the list view after success
        } catch (error) {
            console.error(t("create_progress_view_failed"), error)
        }
    }

    const handleCheckInSelect = (
        checkInId: string,
        isSelected: boolean,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.stopPropagation()

        if (isSelected) {
            setSelectedCheckInIds((prev) => [...prev, checkInId])
        } else {
            setSelectedCheckInIds((prev) =>
                prev.filter((id) => id !== checkInId)
            )
        }
    }

    const handleImageClick = (url: string) => {
        setSelectedImage(url)
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setSelectedImage(null)
    }

    const toggleExpandCheckIn = (checkInId: string) => {
        if (expandedCheckInIds.includes(checkInId)) {
            setExpandedCheckInIds((prev) =>
                prev.filter((id) => id !== checkInId)
            )
        } else {
            setExpandedCheckInIds((prev) => [...prev, checkInId])
        }
    }

    return (
        <div className="p-4 sm:p-6 w-full max-w-4xl mx-auto">
            <h1 className="text-2xl sm:text-3xl font-bold mb-6">
                {t("create_progress_view")}
            </h1>

            {/* Check-In Selection Section */}
            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-4">
                    {t("select_check_ins")}
                </h2>
                {checkIns.length > 0 ? (
                    <div className="space-y-4">
                        {checkIns.map((checkIn) => (
                            <div
                                key={checkIn.id}
                                className="flex flex-col space-y-2 w-full p-4 border rounded-lg bg-gray-200 dark:bg-gray-800"
                            >
                                {/* Collapsed State: Date and Weight */}
                                <div
                                    className="flex items-center justify-between cursor-pointer"
                                    onClick={() =>
                                        toggleExpandCheckIn(checkIn.id)
                                    }
                                >
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={selectedCheckInIds.includes(
                                                checkIn.id
                                            )}
                                            onChange={(e) =>
                                                handleCheckInSelect(
                                                    checkIn.id,
                                                    e.target.checked,
                                                    e
                                                )
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                            className="shrink-0 mr-3 w-6 h-6"
                                        />
                                        <div>
                                            <p className="text-lg font-semibold">
                                                {new Date(
                                                    checkIn.checkInDate
                                                ).toLocaleDateString()}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {t("weight")}: {checkIn.weight}{" "}
                                                kg
                                            </p>
                                        </div>
                                    </div>
                                    <Button
                                        variant="ghost"
                                        onClick={() =>
                                            toggleExpandCheckIn(checkIn.id)
                                        }
                                    >
                                        {expandedCheckInIds.includes(checkIn.id)
                                            ? t("collapse")
                                            : t("expand")}
                                    </Button>
                                </div>

                                {/* Expanded State: Full Check-In Details */}
                                {expandedCheckInIds.includes(checkIn.id) && (
                                    <div className="mt-4">
                                        <SimpleCheckInItem
                                            checkIn={checkIn}
                                            onImageClick={handleImageClick}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-500">
                        {t("no_check_ins_available")}
                    </p>
                )}
            </div>

            {/* Create Progress View Button */}
            <Button
                variant="default"
                className="text-black dark:text-white bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700"
                onClick={handleCreateProgressView}
                disabled={selectedCheckInIds.length < 2}
            >
                {t("create_progress_view")}
            </Button>

            <ImageModal
                selectedImage={selectedImage}
                modalOpen={modalOpen}
                handleModalClose={handleModalClose}
            />
        </div>
    )
}

export default ProgressViewCreate
