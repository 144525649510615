import React from "react"
import { Card, CardContent } from "@/components/ui/card"
import { Button } from "components/ui/button"
import { useThemeStore } from "stores/useThemeStore"
import { useTranslation } from "react-i18next"

interface StatCardProps {
    title: string
    value: string | number
    footer?: React.ReactNode
    stats?: { label: string; value: string | number }[] // Support array of stats for flexibility
    isDifference?: boolean // Indicates whether this stat is a difference range
    onRangeChange?: (range: "1week" | "1month" | "6months" | "total") => void
    selectedRange?: "1week" | "1month" | "6months" | "total" // The currently selected range
}

const StatCard: React.FC<StatCardProps> = ({
    title,
    value,
    footer,
    isDifference = false,
    onRangeChange,
    selectedRange,
}) => {
    const { theme } = useThemeStore()
    const { t } = useTranslation()
    return (
        <Card
            className={`shadow-md rounded-lg p-6 bg-${
                theme === "dark" ? "gray-800" : "gray-200"
            }`}
        >
            <CardContent className="p-0">
                <h2 className="text-lg font-semibold mb-2">{title}</h2>
                <p className="text-2xl font-bold text-primary">{value}</p>

                {/* If this is a stat card with difference ranges, add buttons */}
                {isDifference && onRangeChange && (
                    <div className="flex space-x-2 justify-center mt-4">
                        <Button
                            variant={
                                selectedRange === "1week"
                                    ? "default"
                                    : "outline"
                            }
                            onClick={() => onRangeChange("1week")}
                        >
                            1W
                        </Button>
                        <Button
                            variant={
                                selectedRange === "1month"
                                    ? "default"
                                    : "outline"
                            }
                            onClick={() => onRangeChange("1month")}
                        >
                            1M
                        </Button>
                        <Button
                            variant={
                                selectedRange === "6months"
                                    ? "default"
                                    : "outline"
                            }
                            onClick={() => onRangeChange("6months")}
                        >
                            6M
                        </Button>
                        <Button
                            variant={
                                selectedRange === "total"
                                    ? "default"
                                    : "outline"
                            }
                            onClick={() => onRangeChange("total")}
                        >
                            {t("total")}
                        </Button>
                    </div>
                )}

                {footer && <div className="mt-4">{footer}</div>}
            </CardContent>
        </Card>
    )
}

export default StatCard
