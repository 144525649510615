import React from "react"
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "components/ui/dialog-image-preview"
import { IconButton } from "components/ui/icon-button"
import { XIcon } from "lucide-react"

interface ImageModalProps {
    selectedImage: string | null
    modalOpen: boolean
    handleModalClose: () => void
}

const ImageModal: React.FC<ImageModalProps> = ({
    selectedImage,
    modalOpen,
    handleModalClose,
}) => {
    return (
        <Dialog open={modalOpen} onOpenChange={handleModalClose}>
            <DialogContent
                aria-describedby={undefined}
                className="bg-black bg-opacity-90 p-0 flex justify-center items-center"
                onClick={handleModalClose} // Close modal when clicking outside the content
            >
                <DialogTitle className="sr-only">Image Preview</DialogTitle>

                <div
                    className="relative max-w-[90vw] max-h-[90vh] overflow-hidden"
                    onClick={(e) => e.stopPropagation()} // Prevent modal close on image click
                >
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Preview"
                            className="w-auto h-auto max-w-[90vw] max-h-[90vh] object-contain"
                        />
                    )}

                    <IconButton
                        className="absolute top-2 right-2 z-10 bg-gray-800 p-2 rounded-full text-white"
                        onClick={handleModalClose}
                    >
                        <XIcon className="h-6 w-6" />
                    </IconButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ImageModal
