/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios"
import apiClient from "utils/api"

// Subscribe a user to the Mailchimp newsletter
export const subscribeToNewsletter = async (email: string) => {
    try {
        await apiClient.post("/newsletter/signup", { email })
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to subscribe")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}
