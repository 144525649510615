import { create } from "zustand"
import {
    getProgressViews,
    createProgressView,
    updateProgressView,
    deleteProgressView,
    getPublicProgressView,
} from "services/progressViewService"

interface ProgressViewState {
    progressViews: ProgressView[]
    error: string | null
    fetchProgressViews: () => Promise<void>
    createProgressView: (checkInIds: string[]) => Promise<void>
    updateProgressView: (id: string, checkInIds: string[]) => Promise<void>
    deleteProgressView: (id: string) => Promise<void>
    setError: (message: string | null) => void
    clearError: () => void
    publicProgressView: PublicProgressViewDto | null
    fetchPublicProgressView: (id: string) => Promise<void>
}

export const useProgressViewStore = create<ProgressViewState>((set) => ({
    progressViews: [],
    error: null,
    publicProgressView: null,
    fetchProgressViews: async () => {
        try {
            const progressViews = await getProgressViews()
            set({ progressViews })
        } catch (error: any) {
            set({ error: error.message })
        }
    },

    createProgressView: async (checkInIds) => {
        try {
            await createProgressView(checkInIds)
            // Refresh progress views after creation
            const progressViews = await getProgressViews()
            set({ progressViews })
        } catch (error: any) {
            set({ error: error.message })
        }
    },

    updateProgressView: async (id, checkInIds) => {
        try {
            await updateProgressView(id, checkInIds)
            // Refresh progress views after update
            const progressViews = await getProgressViews()
            set({ progressViews })
        } catch (error: any) {
            set({ error: error.message })
        }
    },

    deleteProgressView: async (id) => {
        try {
            await deleteProgressView(id)
            // Refresh progress views after deletion
            const progressViews = await getProgressViews()
            set({ progressViews })
        } catch (error: any) {
            set({ error: error.message })
        }
    },

    fetchPublicProgressView: async (id: string) => {
        try {
            const data = await getPublicProgressView(id)
            set({ publicProgressView: data, error: null })
        } catch (error) {
            set({ publicProgressView: null, error: error.message })
        }
    },

    setError: (message) => set({ error: message }),

    clearError: () => set({ error: null }),
}))
