import React, { useEffect, useState } from "react"
import { useCheckInStore } from "stores/useCheckInStore"
import { useTranslation } from "react-i18next"
import StatCard from "components/dashboard/StatCard"
import TrendChart from "components/dashboard/TrendChart"
import CheckInComparison from "components/dashboard/CheckInComparison"
import ImageModal from "components/shared/ImageModal"
import ErrorMessage from "components/shared/ErrorMessage"
import { Card, CardContent } from "components/ui/card"
import { useThemeStore } from "stores/useThemeStore"

const Dashboard: React.FC = () => {
    const { theme } = useThemeStore()
    const { t } = useTranslation()
    const { checkIns, fetchCheckIns } = useCheckInStore((state) => ({
        checkIns: state.checkIns,
        fetchCheckIns: state.fetchCheckIns,
    }))

    const [error, setError] = useState<string | null>(null)
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const [selectedWeightDifferenceRange, setSelectedWeightDifferenceRange] =
        useState<"1week" | "1month" | "6months" | "total">("total")

    const [
        selectedCircumferenceDifferenceRange,
        setSelectedCircumferenceDifferenceRange,
    ] = useState<"1week" | "1month" | "6months" | "total">("total")

    useEffect(() => {
        const loadCheckIns = async () => {
            try {
                await fetchCheckIns()
            } catch (err) {
                setError(t("fetch_error"))
            }
        }
        loadCheckIns()
    }, [fetchCheckIns, t])

    const latestCheckIn = checkIns[0] || null
    const earliestCheckIn = checkIns[checkIns.length - 1] || null

    const weightData = checkIns.map((checkIn) => ({
        date: new Date(checkIn.checkInDate).toLocaleDateString(),
        value: checkIn.weight,
    }))

    const circumferenceData = checkIns.map((checkIn) => ({
        date: new Date(checkIn.checkInDate).toLocaleDateString(),
        value:
            checkIn.chestMeasurement +
            checkIn.waistMeasurement +
            checkIn.hipMeasurement +
            checkIn.armMeasurement +
            checkIn.legMeasurement,
    }))

    const totalCircumference = latestCheckIn
        ? latestCheckIn.chestMeasurement +
          latestCheckIn.waistMeasurement +
          latestCheckIn.hipMeasurement +
          latestCheckIn.armMeasurement +
          latestCheckIn.legMeasurement
        : t("na")

    const getCheckInsWithinRange = (
        range: "1week" | "1month" | "6months" | "total"
    ) => {
        const now = new Date()
        const rangeInMs = {
            "1week": 7 * 24 * 60 * 60 * 1000,
            "1month": 30 * 24 * 60 * 60 * 1000,
            "6months": 6 * 30 * 24 * 60 * 60 * 1000,
            total: null,
        }
        const rangeMs = rangeInMs[range]

        return range === "total"
            ? checkIns
            : checkIns.filter(
                  (checkIn) =>
                      new Date(checkIn.checkInDate).getTime() >=
                      now.getTime() - (rangeMs as number)
              )
    }

    const weightDifferenceCheckInsInRange = getCheckInsWithinRange(
        selectedWeightDifferenceRange
    )
    const earliestWeightDifferenceCheckInInRange =
        weightDifferenceCheckInsInRange.length > 0
            ? weightDifferenceCheckInsInRange[
                  weightDifferenceCheckInsInRange.length - 1
              ]
            : null

    const weightDifference =
        latestCheckIn && earliestWeightDifferenceCheckInInRange
            ? (
                  latestCheckIn.weight -
                  earliestWeightDifferenceCheckInInRange.weight
              ).toFixed(2)
            : t("na")

    const circumferenceDifferenceCheckInsInRange = getCheckInsWithinRange(
        selectedCircumferenceDifferenceRange
    )
    const earliestCircumferenceDifferenceCheckInInRange =
        circumferenceDifferenceCheckInsInRange.length > 0
            ? circumferenceDifferenceCheckInsInRange[
                  circumferenceDifferenceCheckInsInRange.length - 1
              ]
            : null

    const circumferenceDifference =
        latestCheckIn && earliestCircumferenceDifferenceCheckInInRange
            ? (
                  latestCheckIn.chestMeasurement +
                  latestCheckIn.waistMeasurement +
                  latestCheckIn.hipMeasurement +
                  latestCheckIn.armMeasurement +
                  latestCheckIn.legMeasurement -
                  (earliestCircumferenceDifferenceCheckInInRange.chestMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.waistMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.hipMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.armMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.legMeasurement)
              ).toFixed(2)
            : t("na")

    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl)
        setModalOpen(true)
    }

    return (
        <div className="flex flex-col items-center p-4 sm:p-6 w-full bg-white dark:bg-black text-black dark:text-white">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4">
                {t("dashboard")}
            </h1>

            {error && <ErrorMessage message={error} />}

            {/* Stat Cards Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mt-4 w-full">
                <StatCard
                    title={t("current_weight")}
                    value={`${latestCheckIn?.weight || t("na")} kg`}
                />
                <StatCard
                    title={t("total_circumference")}
                    value={`${totalCircumference} cm`}
                />
                <StatCard
                    title={t("weight_difference")}
                    value={`${weightDifference} kg`}
                    isDifference={true}
                    onRangeChange={setSelectedWeightDifferenceRange}
                    selectedRange={selectedWeightDifferenceRange}
                />
                <StatCard
                    title={t("circumference_difference")}
                    value={`${circumferenceDifference} cm`}
                    isDifference={true}
                    onRangeChange={setSelectedCircumferenceDifferenceRange}
                    selectedRange={selectedCircumferenceDifferenceRange}
                />
            </div>

            <div className="flex flex-col items-center w-full max-w-4xl mt-8 space-y-8">
                <CheckInComparison
                    checkIn={latestCheckIn}
                    handleImageClick={handleImageClick}
                    title={t("latest_picture")}
                />
                <CheckInComparison
                    checkIn={earliestCheckIn}
                    handleImageClick={handleImageClick}
                    title={t("earliest_picture")}
                />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8 max-w-4xl w-full">
                <Card className="shadow-lg bg-white dark:bg-black">
                    <CardContent>
                        <TrendChart
                            data={weightData}
                            title={t("weight_trend")}
                            dataKey="value"
                            color={theme === "dark" ? "#ffffff" : "#000000"}
                        />
                    </CardContent>
                </Card>
                <Card className="shadow-lg bg-white dark:bg-black">
                    <CardContent>
                        <TrendChart
                            data={circumferenceData}
                            title={t("circumference_trend")}
                            dataKey="value"
                            color={theme === "dark" ? "#ffffff" : "#000000"}
                        />
                    </CardContent>
                </Card>
            </div>

            <ImageModal
                selectedImage={selectedImage}
                modalOpen={modalOpen}
                handleModalClose={() => setModalOpen(false)}
            />
        </div>
    )
}

export default Dashboard
