import React, { useState, FormEvent } from "react"
import { useNavigate, Link } from "react-router-dom"
import { useAuthStore } from "stores/useAuthStore"
import { useTranslation } from "react-i18next"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Loader2 } from "lucide-react"

const Login: React.FC = () => {
    const { t } = useTranslation() // Use translation hook
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null) // State for error messages
    const login = useAuthStore((state) => state.login)
    const navigate = useNavigate()

    const handleLogin = async (event: FormEvent) => {
        event.preventDefault()
        setLoading(true)
        setErrorMessage(null) // Reset error message on form submit
        try {
            await login(email, password)
            navigate("/dashboard")
        } catch (error) {
            setErrorMessage(t("login_failed"))
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex items-center justify-center h-screen bg-white dark:bg-black">
            <div className="bg-white dark:bg-black p-8 shadow-lg rounded-lg max-w-md w-full">
                <h1 className="text-3xl font-bold mb-6 text-center text-black dark:text-white">
                    {t("login")}
                </h1>

                {errorMessage && (
                    <p className="text-red-500 text-center mb-4">
                        {errorMessage}
                    </p>
                )}

                <form onSubmit={handleLogin} className="space-y-6">
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-black dark:text-white"
                        >
                            {t("email")}
                        </label>
                        <Input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full mt-2 bg-white dark:bg-black text-black dark:text-white"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-black dark:text-white"
                        >
                            {t("password")}
                        </label>
                        <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full mt-2 bg-white dark:bg-black text-black dark:text-white"
                        />
                    </div>
                    <Button
                        type="submit"
                        className="relative w-full flex justify-center items-center text-white bg-black dark:bg-white dark:text-black"
                        disabled={loading}
                    >
                        {loading ? (
                            <Loader2 className="h-5 w-5 animate-spin" />
                        ) : (
                            t("login")
                        )}
                    </Button>
                </form>

                {/* Back to Home Button */}
                <div className="text-center mt-6">
                    <Link
                        to="/"
                        className="text-black dark:text-white hover:underline"
                    >
                        {t("back_to_home")}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Login
