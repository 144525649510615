import React from "react"
import { Button } from "components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover"
import { Calendar } from "components/ui/calendar"

interface DatePickerInputProps {
    label: string
    selectedDate: Date
    onDateChange: (date: Date) => void
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({
    label,
    selectedDate,
    onDateChange,
}) => {
    return (
        <div>
            <label className="text-sm font-medium text-black dark:text-gray-200 rounded-md">
                {label}
            </label>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        className="w-full justify-start text-left font-normal bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                    >
                        {selectedDate
                            ? selectedDate.toLocaleDateString()
                            : "Select a date"}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0 bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md">
                    <Calendar
                        mode="single"
                        selected={selectedDate}
                        onSelect={(date) =>
                            onDateChange(
                                new Date(
                                    date.getFullYear(),
                                    date.getMonth(),
                                    date.getDate()
                                )
                            )
                        }
                        initialFocus
                        className="bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                    />
                </PopoverContent>
            </Popover>
        </div>
    )
}

export default DatePickerInput
