import React, { useRef, useState } from "react"
import { XIcon, Loader2 } from "lucide-react"

interface PhotoInputProps {
    label: string
    photoPreview: string | null
    setPhoto: React.Dispatch<React.SetStateAction<File | null>>
    setPhotoPreview: React.Dispatch<React.SetStateAction<string | null>>
}

const PhotoInput: React.FC<PhotoInputProps> = ({
    label,
    photoPreview,
    setPhoto,
    setPhotoPreview,
}) => {
    const [imageLoading, setImageLoading] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            setPhoto(file)

            const reader = new FileReader()
            setImageLoading(true)
            reader.onloadend = () => {
                setPhotoPreview(reader.result as string)
                setImageLoading(false)
            }
            reader.readAsDataURL(file)
        }
    }

    const handleRemoveFile = () => {
        setPhoto(null)
        setPhotoPreview(null)
        if (inputRef.current) {
            inputRef.current.value = ""
        }
    }

    return (
        <div className="photo-container">
            <label className="text-center bg-black text-white rounded-lg px-2 py-1 mb-2">
                {label}
                <input
                    type="file"
                    ref={inputRef}
                    hidden
                    onChange={handleFileChange}
                />
            </label>
            {imageLoading ? (
                <Loader2 className="animate-spin mt-2 h-6 w-6" />
            ) : (
                photoPreview && (
                    <div className="photo-preview">
                        <img src={photoPreview} alt={`${label} Preview`} />
                        <button
                            type="button"
                            className="remove-btn"
                            onClick={handleRemoveFile}
                        >
                            <XIcon />
                        </button>
                    </div>
                )
            )}
        </div>
    )
}

export default PhotoInput
