import React, { useEffect, useState } from "react"
import { useCheckInStore } from "stores/useCheckInStore"
import { useTranslation } from "react-i18next"

import { Button } from "components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "components/ui/dialog"
import { Separator } from "components/ui/separator"
import { Loader2 } from "lucide-react"
import { Textarea } from "components/ui/textarea"
import PhotoInput from "components/check-in/form/PhotoInput"
import FormFields from "./form/FormFields"
import DatePickerInput from "components/shared/DatePickerInput"

interface CheckInFormProps {
    open: boolean
    handleClose: () => void
    initialValues?: CheckIn | null
    isEditing?: boolean
}

const CheckInForm: React.FC<CheckInFormProps> = ({
    open,
    handleClose,
    initialValues,
    isEditing = false,
}) => {
    const { createCheckIn, updateCheckIn } = useCheckInStore((state) => ({
        createCheckIn: state.createCheckIn,
        updateCheckIn: state.updateCheckIn,
    }))

    const { t } = useTranslation()

    const [weight, setWeight] = useState<number | "">(0)
    const [chestMeasurement, setChestMeasurement] = useState<number | "">(0)
    const [waistMeasurement, setWaistMeasurement] = useState<number | "">(0)
    const [hipMeasurement, setHipMeasurement] = useState<number | "">(0)
    const [armMeasurement, setArmMeasurement] = useState<number | "">(0)
    const [legMeasurement, setLegMeasurement] = useState<number | "">(0)
    const [frontPhoto, setFrontPhoto] = useState<File | null>(null)
    const [sidePhoto, setSidePhoto] = useState<File | null>(null)
    const [backPhoto, setBackPhoto] = useState<File | null>(null)
    const [note, setNote] = useState<string>("")
    const [checkInDate, setCheckInDate] = useState<Date>(new Date())

    const [frontPhotoPreview, setFrontPhotoPreview] = useState<string | null>(
        null
    )
    const [sidePhotoPreview, setSidePhotoPreview] = useState<string | null>(
        null
    )
    const [backPhotoPreview, setBackPhotoPreview] = useState<string | null>(
        null
    )

    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [initialFormValues, setInitialFormValues] = useState({
        weight: 0,
        chestMeasurement: 0,
        waistMeasurement: 0,
        hipMeasurement: 0,
        armMeasurement: 0,
        legMeasurement: 0,
        frontPhotoUrl: null,
        sidePhotoUrl: null,
        backPhotoUrl: null,
        note: "",
        checkInDate: new Date(),
    })

    useEffect(() => {
        if (initialValues) {
            setInitialFormValues({
                weight: initialValues.weight || 0,
                chestMeasurement: initialValues.chestMeasurement || 0,
                waistMeasurement: initialValues.waistMeasurement || 0,
                hipMeasurement: initialValues.hipMeasurement || 0,
                armMeasurement: initialValues.armMeasurement || 0,
                legMeasurement: initialValues.legMeasurement || 0,
                frontPhotoUrl: initialValues.frontPictureUrl || null,
                sidePhotoUrl: initialValues.sidePictureUrl || null,
                backPhotoUrl: initialValues.backPictureUrl || null,
                note: "",
                checkInDate: new Date(),
            })

            setWeight(initialValues.weight || "")
            setChestMeasurement(initialValues.chestMeasurement || "")
            setWaistMeasurement(initialValues.waistMeasurement || "")
            setHipMeasurement(initialValues.hipMeasurement || "")
            setArmMeasurement(initialValues.armMeasurement || "")
            setLegMeasurement(initialValues.legMeasurement || "")
        }

        if (isEditing && initialValues) {
            setFrontPhotoPreview(initialValues.frontPictureUrl || null)
            setSidePhotoPreview(initialValues.sidePictureUrl || null)
            setBackPhotoPreview(initialValues.backPictureUrl || null)
            setNote(initialValues.note || "")
            setCheckInDate(
                initialValues.checkInDate
                    ? new Date(initialValues.checkInDate)
                    : new Date()
            )
        } else {
            setFrontPhotoPreview(null)
            setSidePhotoPreview(null)
            setBackPhotoPreview(null)
            setNote("")
            setCheckInDate(new Date())
        }
    }, [initialValues, isEditing])

    const handleChange = (field: string, value: string | number) => {
        let parsedValue: number | "" =
            value === "" ? "" : parseFloat(value as string)
        if (typeof value === "string" && isNaN(Number(parsedValue))) {
            parsedValue = ""
        }

        switch (field) {
            case "weight":
                setWeight(parsedValue)
                break
            case "chestMeasurement":
                setChestMeasurement(parsedValue)
                break
            case "waistMeasurement":
                setWaistMeasurement(parsedValue)
                break
            case "hipMeasurement":
                setHipMeasurement(parsedValue)
                break
            case "armMeasurement":
                setArmMeasurement(parsedValue)
                break
            case "legMeasurement":
                setLegMeasurement(parsedValue)
                break
            default:
                break
        }
    }

    const resetFormToInitialValues = () => {
        setWeight(initialFormValues.weight)
        setChestMeasurement(initialFormValues.chestMeasurement)
        setWaistMeasurement(initialFormValues.waistMeasurement)
        setHipMeasurement(initialFormValues.hipMeasurement)
        setArmMeasurement(initialFormValues.armMeasurement)
        setLegMeasurement(initialFormValues.legMeasurement)
        setFrontPhotoPreview(initialFormValues.frontPhotoUrl)
        setSidePhotoPreview(initialFormValues.sidePhotoUrl)
        setBackPhotoPreview(initialFormValues.backPhotoUrl)
        setNote(initialFormValues.note)
        setErrorMessage(null)
    }

    const handleCloseAndReset = () => {
        resetFormToInitialValues()
        handleClose()
    }

    const utcCheckInDate = new Date(
        Date.UTC(
            checkInDate.getFullYear(),
            checkInDate.getMonth(),
            checkInDate.getDate()
        )
    )

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        setLoading(true)
        setErrorMessage(null)

        const numericWeight =
            typeof weight === "number" ? weight : parseFloat(weight as string)
        const numericChestMeasurement =
            typeof chestMeasurement === "number"
                ? chestMeasurement
                : parseFloat(chestMeasurement as string)
        const numericWaistMeasurement =
            typeof waistMeasurement === "number"
                ? waistMeasurement
                : parseFloat(waistMeasurement as string)
        const numericHipMeasurement =
            typeof hipMeasurement === "number"
                ? hipMeasurement
                : parseFloat(hipMeasurement as string)
        const numericArmMeasurement =
            typeof armMeasurement === "number"
                ? armMeasurement
                : parseFloat(armMeasurement as string)
        const numericLegMeasurement =
            typeof legMeasurement === "number"
                ? legMeasurement
                : parseFloat(legMeasurement as string)

        try {
            if (isEditing && initialValues) {
                await updateCheckIn(
                    initialValues.id,
                    numericWeight,
                    numericChestMeasurement,
                    numericWaistMeasurement,
                    numericHipMeasurement,
                    numericArmMeasurement,
                    numericLegMeasurement,
                    note,
                    frontPhoto,
                    sidePhoto,
                    backPhoto,
                    utcCheckInDate
                )
            } else {
                await createCheckIn(
                    numericWeight,
                    numericChestMeasurement,
                    numericWaistMeasurement,
                    numericHipMeasurement,
                    numericArmMeasurement,
                    numericLegMeasurement,
                    note,
                    frontPhoto,
                    sidePhoto,
                    backPhoto,
                    utcCheckInDate
                )
            }
            handleCloseAndReset()
        } catch (error: any) {
            setErrorMessage(error.message || t("submit_error"))
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onOpenChange={handleClose}>
            <DialogContent
                className="max-w-lg w-full mx-auto p-4 bg-white dark:bg-gray-800 text-black dark:text-white overflow-y-auto md:max-w-lg"
                aria-describedby={undefined}
            >
                <DialogHeader>
                    <DialogTitle className="text-center text-xl font-semibold mb-4">
                        {isEditing ? t("editCheckIn") : t("addCheckIn")}
                    </DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid gap-4">
                        <DatePickerInput
                            label="Check-In Date"
                            selectedDate={checkInDate}
                            onDateChange={setCheckInDate}
                        />
                        <FormFields
                            weight={weight}
                            chestMeasurement={chestMeasurement}
                            waistMeasurement={waistMeasurement}
                            hipMeasurement={hipMeasurement}
                            armMeasurement={armMeasurement}
                            legMeasurement={legMeasurement}
                            handleChange={handleChange}
                        />
                        <div>
                            <label htmlFor="note">Note</label>
                            <Textarea
                                id="note"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                className=" bg-gray-200 dark:bg-gray-700 text-black dark:text-gray-200 rounded-md"
                                placeholder="Add your note"
                            />
                        </div>
                    </div>

                    <Separator />

                    <div className="grid gap-4">
                        <div className="photo-input-grid grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <PhotoInput
                                label="Front photo"
                                photoPreview={frontPhotoPreview}
                                setPhoto={setFrontPhoto}
                                setPhotoPreview={setFrontPhotoPreview}
                            />
                            <PhotoInput
                                label="Side photo"
                                photoPreview={sidePhotoPreview}
                                setPhoto={setSidePhoto}
                                setPhotoPreview={setSidePhotoPreview}
                            />
                            <PhotoInput
                                label="Back photo"
                                photoPreview={backPhotoPreview}
                                setPhoto={setBackPhoto}
                                setPhotoPreview={setBackPhotoPreview}
                            />
                        </div>
                    </div>

                    {errorMessage && (
                        <p className="text-red-500">{errorMessage}</p>
                    )}

                    <div className="flex justify-end space-x-4 mt-6">
                        <Button
                            type="submit"
                            variant="default"
                            disabled={loading}
                        >
                            {loading ? (
                                <Loader2 className="animate-spin h-5 w-5" />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                        <Button variant="outline" onClick={handleCloseAndReset}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default CheckInForm
