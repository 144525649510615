import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import { initializeAuth } from "stores/useAuthStore"
import "./index.css"
import * as Sentry from "@sentry/react"

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
})

// Initialize authentication and then render the app
initializeAuth().then(() => {
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
        <React.StrictMode>
            <Router>
                <App />
            </Router>
        </React.StrictMode>
    )
})
