import { create } from "zustand"

interface ThemeState {
    theme: "light" | "dark"
    toggleTheme: () => void
}

export const useThemeStore = create<ThemeState>((set) => ({
    // Set initial state based on localStorage or default to "light"
    theme: (localStorage.getItem("theme") as "light" | "dark") || "light",
    toggleTheme: () =>
        set((state) => {
            const newTheme = state.theme === "light" ? "dark" : "light"
            localStorage.setItem("theme", newTheme)
            return { theme: newTheme }
        }),
}))

// Initialize the theme based on localStorage
export const initializeTheme = () => {
    const storedTheme = localStorage.getItem("theme")
    if (storedTheme) {
        useThemeStore.setState({ theme: storedTheme as "light" | "dark" })
    }
}
