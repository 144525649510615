import { create } from "zustand"
import i18n from "utils/i18n"

interface LanguageState {
    language: string
    setLanguage: (language: string) => void
}

export const useLanguageStore = create<LanguageState>((set) => ({
    language: localStorage.getItem("language") || i18n.language || "en", // Default to saved language or 'en'
    setLanguage: (language: string) => {
        i18n.changeLanguage(language)
        localStorage.setItem("language", language)
        set({ language })
    },
}))
