import React, { useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import MainLayout from "layouts/MainLayout"
import Dashboard from "pages/Dashboard"
import Login from "pages/Login"
import Register from "pages/Register"
import Profile from "pages/Profile"
import ProtectedRoute from "components/ProtectedRoute"
import LandingPage from "pages/LandingPage"
import CheckInPage from "pages/CheckInPage"
import SettingsPage from "pages/SettingsPage"

import { initializeTheme } from "stores/useThemeStore"
import ProgressView from "pages/public/ProgressView"
import ProgressViewList from "pages/ProgressViewList"
import ProgressViewCreate from "pages/ProgressViewCreate"

const App: React.FC = () => {
    useEffect(() => {
        initializeTheme()
    }, [])

    return (
        <Routes>
            {/* Public routes without MainLayout */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
                path="/public/progress-view/:id"
                element={<ProgressView />}
            />

            {/* Routes with MainLayout */}
            <Route element={<MainLayout />}>
                <Route
                    path="/dashboard"
                    element={<ProtectedRoute component={Dashboard} />}
                />
                <Route
                    path="/profile"
                    element={<ProtectedRoute component={Profile} />}
                />
                <Route
                    path="/checkin"
                    element={<ProtectedRoute component={CheckInPage} />}
                />
                <Route
                    path="/settings"
                    element={<ProtectedRoute component={SettingsPage} />}
                />
                <Route
                    path="/progress-views"
                    element={<ProtectedRoute component={ProgressViewList} />}
                />
                <Route
                    path="/progress-views/create"
                    element={<ProtectedRoute component={ProgressViewCreate} />}
                />
            </Route>
        </Routes>
    )
}

export default App
