import axios from "axios"
import apiClient from "utils/api"

// Create a check-in
export const createCheckIn = async (
    weight: number,
    chestMeasurement: number,
    waistMeasurement: number,
    hipMeasurement: number,
    armMeasurement: number,
    legMeasurement: number,
    note: string,
    checkInDate: Date
) => {
    // Create a JSON object for the measurements
    const measurements = {
        weight,
        chestMeasurement,
        waistMeasurement,
        hipMeasurement,
        armMeasurement,
        legMeasurement,
        note,
        checkInDate,
    }

    try {
        // Send measurements and note as JSON
        const response = await apiClient.post("/checkin", measurements, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to create check-in")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Upload photos
export const uploadPhotos = async (
    checkInId: string,
    frontPhoto: File | null,
    sidePhoto: File | null,
    backPhoto: File | null
) => {
    const formData = new FormData()

    if (frontPhoto) {
        formData.append("frontPhoto", frontPhoto)
    }
    if (sidePhoto) {
        formData.append("sidePhoto", sidePhoto)
    }
    if (backPhoto) {
        formData.append("backPhoto", backPhoto)
    }

    try {
        const response = await apiClient.post(
            `/checkin/${checkInId}/photos`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to upload photos")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Fetch check-ins
export const getCheckIns = async () => {
    try {
        const response = await apiClient.get("/checkin")
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to fetch check-ins")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Update a check-in
export const updateCheckIn = async (
    id: string,
    weight: number,
    chestMeasurement: number,
    waistMeasurement: number,
    hipMeasurement: number,
    armMeasurement: number,
    legMeasurement: number,
    note: string,
    checkInDate: Date
) => {
    // Create a JSON object for the measurements
    const measurements = {
        weight,
        chestMeasurement,
        waistMeasurement,
        hipMeasurement,
        armMeasurement,
        legMeasurement,
        note,
        checkInDate,
    }

    try {
        // Send measurements and note as JSON
        const response = await apiClient.put(`/checkin/${id}`, measurements, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to update check-in")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Update photos
export const updatePhotos = async (
    id: string,
    frontPhoto: File | null,
    sidePhoto: File | null,
    backPhoto: File | null
) => {
    const formData = new FormData()

    if (frontPhoto) {
        formData.append("frontPhoto", frontPhoto)
    }
    if (sidePhoto) {
        formData.append("sidePhoto", sidePhoto)
    }
    if (backPhoto) {
        formData.append("backPhoto", backPhoto)
    }

    try {
        const response = await apiClient.put(
            `/checkin/${id}/photos`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to update photos")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Delete a check-in
export const deleteCheckIn = async (id: string) => {
    try {
        await apiClient.delete(`/checkin/${id}`)
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to delete check-in")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}
